<template>
  <div>
    <div style="margin-top: 30px;margin-left: 100px;margin-bottom: 20px">
      <el-date-picker
          v-model="times"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
      >
      </el-date-picker>

      <el-select v-model="value" class="m-2" placeholder="请选择开挖方式" size="large">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
      <el-select v-model="value2" class="m-2" placeholder="请选择围岩等级" size="large">
        <el-option
            v-for="item in options2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>

<!--      <el-select v-model="value3" class="m-2" placeholder="请选择围岩等级" size="large">-->
<!--        <el-option-->
<!--            v-for="item in options3"-->
<!--            :key="item.flag"-->
<!--            :label="item.operatingpoint"-->
<!--            :value="item.flag"-->
<!--        />-->
<!--      </el-select>-->

      <el-button @click="seltj">查询</el-button>
    </div>
    <div style="margin-left: 100px">
      <el-tag>开挖每榀平均工效</el-tag>
      <vxe-table
          border
          resizable
          :align="allAlign"
          :data="tableData"
          size="small"
          highlight-hover-row
          ref="xTable1"
          :export-config="{}"
      >
        <vxe-column field="opername" title="工点" width="380"></vxe-column>
        <vxe-colgroup title="循环">
          <vxe-column field="all" title="平均" width="90"></vxe-column>
          <vxe-column field="allmax" title="最大" width="90"></vxe-column>
          <vxe-column field="allmix" title="最小" width="90"></vxe-column>
        </vxe-colgroup>
        <vxe-colgroup title="开挖">
          <vxe-column field="kw" title="平均" width="90"></vxe-column>
          <vxe-column field="kwmax" title="最大" width="90"></vxe-column>
          <vxe-column field="kwmix" title="最小" width="90"></vxe-column>
        </vxe-colgroup>
        <vxe-colgroup title="出渣">
          <vxe-column field="cz" title="平均" width="90"></vxe-column>
          <vxe-column field="czmax" title="最大" width="90"></vxe-column>
          <vxe-column field="czmix" title="最小" width="90"></vxe-column>
        </vxe-colgroup>
        <vxe-colgroup title="初喷">
          <vxe-column field="cp" title="平均" width="90"></vxe-column>
          <vxe-column field="cpmax" title="最大" width="90"></vxe-column>
          <vxe-column field="cpmix" title="最小" width="90"></vxe-column>
        </vxe-colgroup>
        <vxe-colgroup title="立架">
          <vxe-column field="lj" title="平均" width="90"></vxe-column>
          <vxe-column field="ljmax" title="最大" width="90"></vxe-column>
          <vxe-column field="ljmix" title="最小" width="90"></vxe-column>
        </vxe-colgroup>
        <vxe-colgroup title="喷锚">
          <vxe-column field="pm" title="平均" width="90"></vxe-column>
          <vxe-column field="pmmax" title="最大" width="90"></vxe-column>
          <vxe-column field="pmmix" title="最小" width="90"></vxe-column>
        </vxe-colgroup>
        <vxe-column field="mile" title="平均循环进尺" width="180"></vxe-column>

      </vxe-table>
    </div>
    <div id="container" ref="chart">
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import dayjs from "dayjs";
export default {
  name: "testtj",
  data(){
    return{
      times: '',
      tableData: [],
      allAlign: 'center',
      value: '',
      value2: '',
      value3: '',
      options3: [],
      options:[{
        value: '台阶法',
        label: '台阶法',
      },
        {
          value: '微台阶法',
          label: '微台阶法',
        },
        {
          value: '全断面',
          label: '全断面',
        }],
      options2:[{
        value: 'Ⅱ',
        label: 'Ⅱ',
      },
        {
          value: 'Ⅲ',
          label: 'Ⅲ',
        },
        {
          value: 'Ⅳ',
          label: 'Ⅳ',
        },
        {
          value: 'Ⅴ',
          label: 'Ⅴ',
        },
        {
          value: 'Ⅵ',
          label: 'Ⅵ',
        }]
    }
  },
  methods:{
    seltj(){
      this.axios.post("/collectmain/cleandataall", (response) => {
        this.tableData = response.obj;
        setTimeout(this.tb, 1000)
      },{
        time: this.times,
        level: this.value2,
        daunmian: this.value
      })
    },
    tb(){
      let num = [];
        num.push(this.tableData[0].all2);
        num.push(this.tableData[0].kw2);
        num.push(this.tableData[0].cz2);
        num.push(this.tableData[0].cp2);
        num.push(this.tableData[0].lj2);
        num.push(this.tableData[0].pm2);
        num.push(this.tableData[0].mile2);
      console.log(num)
      var myChart = echarts.init(document.getElementById('container'));
      // 绘制图表
      myChart.setOption({
        title: {
          text: ''
        },
        tooltip: {},
        xAxis: {
          type: 'category',
          data: ['循环', '开挖', '出渣', '初喷', '立架', '喷锚', '进尺']
        },
        yAxis: {},
        series: [
          {
            name: '超耗量',
            type: 'bar',
            data: num
          }
        ]
      });
    }
  }
}
</script>

<style scoped>
#container {
  box-sizing: border-box;
  height: 400px;
  width: 80%;
  margin: 0 auto;
}
</style>